import clsx from 'clsx';
import Marquee from 'react-fast-marquee';
import { match } from 'ts-pattern';

import { GetFeaturedActivity } from '@endaoment-frontend/api';

import { RecentDonation, RecentTransfer } from '../../components/activitySection';

import styles from './RecentActivity.module.scss';

export const RecentActivity = () => {
  const { data: recentActivity } = GetFeaturedActivity.useQuery([]);

  return (
    <div className={styles['container']}>
      <div>
        <span className={styles['label']}>Recent Activity</span>
        <Marquee pauseOnHover className={styles['marquee']} gradient={false} speed={40}>
          {recentActivity?.map((activity, i) => (
            <div key={`slide-${i}`} className={clsx(styles['marquee__item'])}>
              {match(activity)
                .with({ type: 'donation' }, activity => <RecentDonation subject='featured' activity={activity} />)
                .with({ type: 'grant' }, activity => <RecentTransfer subject='featured' activity={activity} />)
                .with({ type: 'internal_transfer' }, activity => (
                  <RecentTransfer subject='featured' activity={activity} />
                ))
                .otherwise(() => (
                  <></>
                ))}
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};
