import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';

import { useAuthType } from '@endaoment-frontend/authentication';
import { getChainForChainId } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import type { FundListing } from '@endaoment-frontend/types';
import { ChainIcon, StarIcon } from '@endaoment-frontend/ui/icons';
import { Avatar, Card, ProgressBar, Tooltip } from '@endaoment-frontend/ui/shared';
import { formatCurrency, formatUsdc, getFundTotalValues, parseHtml, stringToColor } from '@endaoment-frontend/utils';

import styles from './CommunityFundCard.module.scss';

export type CommunityFundCardProps = {
  fund: Pick<
    FundListing,
    | 'advisor'
    | 'chainId'
    | 'description'
    | 'grantsGiven'
    | 'id'
    | 'inTransitBuyUsdcAmount'
    | 'inTransitSellUsdcAmount'
    | 'investedUsdc'
    | 'lifetimeDonationsUsdc'
    | 'logo'
    | 'name'
    | 'shortDescription'
    | 'totalGrantedUsdc'
    | 'type'
    | 'usdcBalance'
    | 'vanityUrl'
  >;
  className?: string;
};

export const CommunityFundCard = ({ fund, className }: CommunityFundCardProps) => {
  const { totalImpact, completedPercent } = getFundTotalValues(fund);

  const chainName = getChainForChainId(fund.chainId).name;

  const { isWalletAuth } = useAuthType();

  return (
    <Link
      href={fund.vanityUrl ? routes.app.fund({ vanityUrl: fund.vanityUrl }) : routes.app.fund({ id: fund.id })}
      className={clsx(styles['community-fund-card'], className)}>
      <Card>
        <header>
          <div className={styles['avatar-container']}>
            {!!isWalletAuth && (
              <Tooltip content={`This fund is deployed on ${chainName}`} className={styles['chain-icon']}>
                <ChainIcon chainId={fund.chainId} filled light />
              </Tooltip>
            )}
            <Avatar className={styles['avatar']}>
              {fund.logo ? (
                <img src={fund.logo} alt={`${fund.name} logo`} loading='lazy' />
              ) : (
                <StarIcon
                  startColor={stringToColor(fund.name)}
                  endColor={stringToColor(fund.name.split('').reverse().join(''))}
                />
              )}
            </Avatar>
          </div>
          <div className={styles['total']}>
            <h3 className={styles['total-amount']}>
              {formatCurrency(formatUsdc(totalImpact), { fraction: 2, lowercase: true })}
            </h3>
            <p className={styles['total-label']}>Total Impact</p>
          </div>
        </header>
        <div className={styles['content']}>
          <div className={styles['content-header']}>
            <h3 className={styles['content-name']}>{fund.name}</h3>
          </div>
          <div className={styles['content-description']}>{parseHtml(fund.shortDescription ?? fund.description)}</div>
        </div>
        {Number(fund.lifetimeDonationsUsdc) > 0 && (
          <ProgressBar progress={completedPercent} className={styles['progress']}>
            <span>
              <em>{Math.round(completedPercent)}%</em> Distributed
            </span>
            {fund.grantsGiven > 0 && (
              <span>
                <em>
                  {fund.grantsGiven} Grant{fund.grantsGiven > 1 && 's'}
                </em>
              </span>
            )}
          </ProgressBar>
        )}
        <div />
        <div className={styles['footer']}>
          <span>
            {'Managed by '}
            <em>
              {fund.advisor.firstName} {fund.advisor.lastName}
            </em>
          </span>
          <em>{fund.type} Fund</em>
        </div>
      </Card>
    </Link>
  );
};
