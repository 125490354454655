import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import type SwiperClass from 'swiper';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { COUNTRY_GEODATA } from '@endaoment-frontend/constants';
import { routes } from '@endaoment-frontend/routes';
import type { Org } from '@endaoment-frontend/types';
import { StarIcon } from '@endaoment-frontend/ui/icons';
import { Card, OrgLogo, SwiperButtons } from '@endaoment-frontend/ui/shared';
import { formatCurrency, formatEin, formatUsdc } from '@endaoment-frontend/utils';

import styles from './FeaturedOrgCarousel.module.scss';

const LargeOrgCard = ({ org }: { org: Org }) => (
  <Card as={Link} href={routes.app.org({ einOrId: org.id })} className={styles['large-org-card']}>
    <div className={clsx(styles['avatar'], styles['avatar-org'])}>
      <div className={styles['avatar-container']}>
        <OrgLogo src={org.logo} orgName={org.name} />
      </div>
    </div>
    <div className={styles['large-org-card__info']}>
      <h6 className={styles['large-org-card__title']}>{org.name}</h6>
      {org.lifetimeContributionsUsdc > 0 && (
        <h6 className={styles['large-org-card__impact']}>
          {formatCurrency(formatUsdc(org.lifetimeContributionsUsdc), { compact: true, lowercase: true })}
          <p>Total Donated</p>
        </h6>
      )}
      <p className={styles['large-org-card__description']}>{org.description}</p>
      <div className={clsx(styles['large-org-card__extra'], styles['large-org-card__bottom'])}>
        {!!org.ein && (
          <span>
            EIN: <em>{formatEin(org.ein)}</em>
          </span>
        )}
        {!!org.address && (
          <span>
            <em>
              {org.address.city},{' '}
              {org.address.country === 'USA'
                ? `${org.address.state}, USA`
                : `${COUNTRY_GEODATA[org.address.country].name}`}
            </em>
          </span>
        )}
      </div>
    </div>
  </Card>
);

// TODO: Combine with FeaturedFundCarousel
export const FeaturedOrgCarousel = ({
  orgs,
  index,
  setIndex,
}: {
  orgs: Array<Org>;
  index: number;
  setIndex: (index: number) => void;
}) => {
  const currOrg = orgs[index];

  if (!currOrg) return <></>;

  return (
    <div className={styles['container']}>
      <Swiper
        modules={[Pagination]}
        onSlideChange={(swiper: SwiperClass) => {
          if (swiper.destroyed) return;
          setIndex(swiper.realIndex);
        }}
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        spaceBetween={0}
        loop
        wrapperTag='ul'
        className={styles['carousel']}>
        {orgs.map(org => (
          <SwiperSlide tag='li' key={org.id + org.ein} className={styles['item']}>
            <LargeOrgCard org={org} />
          </SwiperSlide>
        ))}
        <SwiperButtons />
      </Swiper>
      <div className={styles['label']}>
        <StarIcon />
        <h4>
          <sup>Featured</sup>
          Organizations
        </h4>
        {!!currOrg.staffNotes && (
          <div>
            <h6>Staff Notes</h6>
            <p>{currOrg.staffNotes}</p>
          </div>
        )}
        {!!currOrg.nteeDescription && (
          <div>
            <h6>Category</h6>
            <p>{currOrg.nteeDescription}</p>
          </div>
        )}
      </div>
    </div>
  );
};
