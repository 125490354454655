import { Box } from '@chakra-ui/layout';
import { Link } from '@chakra-ui/next-js';
import { Fade } from '@chakra-ui/transition';
import clsx from 'clsx';
import type { Transition } from 'framer-motion';
import { useState } from 'react';
import { match, P } from 'ts-pattern';

import { GetFeaturedFunds, GetFeaturedOrgs } from '@endaoment-frontend/api';
import { routes } from '@endaoment-frontend/routes';
import { CircleIcon, StarIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader } from '@endaoment-frontend/ui/shared';
import { arraySliceNextN } from '@endaoment-frontend/utils';

import { CommunityFundCard } from '../CommunityFundCard';
import { OrgCard } from '../OrgCard';

import styles from './ExploreCarousels.module.scss';
import { FeaturedFundCarousel } from './FeaturedFundCarousel';
import { FeaturedOrgCarousel } from './FeaturedOrgCarousel';

export const ExploreCarousels = ({ hideShowMore }: { hideShowMore?: boolean }) => {
  const [toggle, setToggle] = useState<'fund' | 'org'>('org');

  const featuredOrgsResult = GetFeaturedOrgs.useQuery([], {
    enabled: toggle === 'org',
  });
  const featuredFundsResult = GetFeaturedFunds.useQuery([], {
    enabled: toggle === 'fund',
  });

  const [currOrgIndex, setCurrOrgIndex] = useState<number>(0);
  const [currFundIndex, setCurrFundIndex] = useState<number>(0);
  const transition: { enter?: Transition; exit?: Transition } = {
    enter: { duration: 1 },
  };

  return (
    <>
      <Box display='flex' flexDirection='row' justifyContent='center' gap='1rem'>
        <Button
          onClick={() => setToggle('org')}
          variation='org'
          filled={toggle === 'org'}
          className={styles['toggle-button']}>
          <CircleIcon />
          Orgs
        </Button>
        <Button
          onClick={() => setToggle('fund')}
          variation='fund'
          filled={toggle === 'fund'}
          className={styles['toggle-button']}>
          <StarIcon />
          Funds
        </Button>
      </Box>
      <Fade in={toggle === 'org'} unmountOnExit transition={transition}>
        {match(featuredOrgsResult)
          .with({ data: P.nullish, isLoading: true }, () => (
            <div className={styles['container']}>
              <Loader size='l' />
            </div>
          ))
          .with(
            {
              data: P.select('orgs', [P.any, ...P.array()]),
            },
            ({ orgs }) => (
              <div className={clsx(styles['container'], styles['container-orgs'])}>
                <div className={styles['container__inner']}>
                  <FeaturedOrgCarousel orgs={orgs} index={currOrgIndex} setIndex={setCurrOrgIndex} />
                  <div className={styles['more-orgs']}>
                    <h5>More Organizations</h5>
                    <div>
                      {arraySliceNextN(orgs, currOrgIndex, 3).map(org => (
                        <OrgCard key={org.id + org.ein} orgOrSubproject={org} className={styles['more-orgs-card']} />
                      ))}
                    </div>
                    {!hideShowMore && (
                      <Button as={Link} href={routes.app.explore()} size='small' filled variation='org'>
                        Show More
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ),
          )
          .otherwise(() => undefined)}
      </Fade>
      <Fade in={toggle === 'fund'} unmountOnExit transition={transition}>
        {match(featuredFundsResult)
          .with({ data: P.nullish, isLoading: true }, () => (
            <div className={styles['container']}>
              <Loader size='l' />
            </div>
          ))
          .with(
            {
              data: P.select('funds', [P.any, ...P.array()]),
            },
            ({ funds }) => (
              <div className={clsx(styles['container'], styles['container-funds'])}>
                <div className={styles['container__inner']}>
                  <FeaturedFundCarousel funds={funds} index={currFundIndex} setIndex={setCurrFundIndex} />
                  <div className={styles['more-funds']}>
                    <h5>More Funds</h5>
                    <div>
                      {arraySliceNextN(funds, currFundIndex, 3).map(fund => (
                        <CommunityFundCard key={fund.id} fund={fund} className={styles['more-funds-card']} />
                      ))}
                    </div>
                    {!hideShowMore && (
                      <Button as={Link} href={routes.app.explore()} size='small' filled variation='fund'>
                        Show More
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ),
          )
          .otherwise(() => undefined)}
      </Fade>
    </>
  );
};
