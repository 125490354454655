import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import Image from 'next/image';
import type SwiperClass from 'swiper';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAuthType } from '@endaoment-frontend/authentication';
import { config } from '@endaoment-frontend/config';
import { routes } from '@endaoment-frontend/routes';
import type { FundListing } from '@endaoment-frontend/types';
import { ChainIcon, StarIcon } from '@endaoment-frontend/ui/icons';
import { Avatar, Card, ProgressBar, SwiperButtons, Tooltip } from '@endaoment-frontend/ui/shared';
import { formatCurrency, formatUsdc, getFundTotalValues, stringToColor } from '@endaoment-frontend/utils';

import styles from './FeaturedFundCarousel.module.scss';

const LargeFundCard = ({ fund }: { fund: FundListing }) => {
  const { totalImpact, completed, completedPercent } = getFundTotalValues(fund);
  const chainName = config.chains.find(c => c.id === fund.chainId)?.name;
  const { isWalletAuth } = useAuthType();
  return (
    <Card
      as={Link}
      className={styles['large-cf-card']}
      href={fund.vanityUrl ? routes.app.fund({ vanityUrl: fund.vanityUrl }) : routes.app.fund({ id: fund.id })}>
      <div className={styles['avatar-container']}>
        {!!isWalletAuth && (
          <Tooltip content={`This fund is deployed on ${chainName}`} className={styles['chain-icon']}>
            <ChainIcon chainId={fund.chainId} filled light />
          </Tooltip>
        )}
        <Avatar className={styles['avatar']}>
          {fund.logo ? (
            <Image src={fund.logo} alt={`${fund.name} logo`} width={200} height={200} />
          ) : (
            <StarIcon
              startColor={stringToColor(fund.name)}
              endColor={stringToColor(fund.name.split('').reverse().join(''))}
            />
          )}
        </Avatar>
      </div>
      <div className={styles['large-cf-card__info']}>
        <h6 className={styles['large-cf-card__title']}>
          {fund.name}
          <p>
            Managed by&nbsp;
            <em>
              {fund.advisor.firstName} {fund.advisor.lastName}
            </em>
          </p>
        </h6>
        <h6 className={styles['large-cf-card__impact']}>
          {formatCurrency(formatUsdc(totalImpact), { compact: true, lowercase: true })}
          <p>Total Impact</p>
        </h6>
        <p className={styles['large-cf-card__description']}>{fund.shortDescription}</p>
        <ProgressBar
          progress={completedPercent}
          className={clsx(styles['large-cf-card__stats'], styles['large-cf-card__bottom'])}>
          <span>
            <em>{Math.round(completedPercent)}%</em>&nbsp;distributed
          </span>
          <span>
            <em>{formatCurrency(formatUsdc(completed), { compact: true, lowercase: true })}</em>&nbsp;contributed
          </span>
        </ProgressBar>
      </div>
    </Card>
  );
};

// TODO: Combine with FeaturedOrgCarousel
export const FeaturedFundCarousel = ({
  funds,
  index,
  setIndex,
}: {
  funds: Array<FundListing>;
  index: number;
  setIndex: (index: number) => void;
}) => {
  const currFund = funds[index];

  if (!currFund) return <></>;

  return (
    <div className={styles['container']}>
      <div className={styles['label']}>
        <StarIcon />
        <h4>
          <sup>Featured</sup>
          Funds
        </h4>
        {!!currFund.staffNotes && (
          <div>
            <h6>Staff Notes</h6>
            <p>{currFund.staffNotes}</p>
          </div>
        )}
        {!!currFund.category && (
          <div>
            <h6>Category</h6>
            <p>{currFund.category}</p>
          </div>
        )}
      </div>
      <Swiper
        modules={[Pagination]}
        onSlideChange={(swiper: SwiperClass) => {
          if (swiper.destroyed) return;
          setIndex(swiper.realIndex);
        }}
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        spaceBetween={0}
        loop
        wrapperTag='ul'
        className={styles['carousel']}>
        {funds.map(fund => (
          <SwiperSlide tag='li' key={fund.id} className={styles['item']}>
            <LargeFundCard fund={fund} />
          </SwiperSlide>
        ))}
        <SwiperButtons />
      </Swiper>
    </div>
  );
};
