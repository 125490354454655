import { Link } from '@chakra-ui/next-js';
import clsx from 'clsx';
import Image from 'next/image';
import type { ReactNode } from 'react';

import { COUNTRY_GEODATA } from '@endaoment-frontend/constants';
import { routes } from '@endaoment-frontend/routes';
import { isSubprojectListing } from '@endaoment-frontend/types';
import { Card, NTEECodes, OrgLogo, Pill } from '@endaoment-frontend/ui/shared';
import { formatEin, formatCurrency, getNTEEMajorCode, parseHtml, formatUsdc } from '@endaoment-frontend/utils';

import styles from './OrgCard.module.scss';
import type { OrgCardProps } from './OrgCard.types';

const OrgCardLinkWrapper = ({
  href,
  children,
  className,
}: {
  href?: string;
  children: ReactNode;
  className?: string;
}) => {
  if (!href) return <div className={clsx(styles['org-card'], className)}>{children}</div>;

  return (
    <Link href={href} className={clsx(styles['org-card'], className)}>
      {children}
    </Link>
  );
};

export const OrgCard = ({ orgOrSubproject, className, showPills = false, includeLink = true }: OrgCardProps) => {
  let href;
  if (includeLink) {
    href = isSubprojectListing(orgOrSubproject)
      ? routes.app.subproject({
          sponsorEinOrId: orgOrSubproject.sponsorOrgEin ?? orgOrSubproject.sponsorOrgId,
          id: orgOrSubproject.id,
        })
      : routes.app.org({ einOrId: orgOrSubproject.ein ?? orgOrSubproject.id });
  }

  return (
    <OrgCardLinkWrapper href={href} className={className}>
      <Card className={styles.inner}>
        {/* TODO: use org's banner when present */}
        <div className={styles.banner}>
          <Image
            src={NTEECodes[getNTEEMajorCode(orgOrSubproject)]}
            alt=''
            className={styles.banner__icon}
            priority
            width={80}
            height={80}
          />
          <div className={styles.banner__bg} />
        </div>

        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.avatar}>
              <div className={styles['avatar-container']}>
                <OrgLogo src={orgOrSubproject.logo} orgName={orgOrSubproject.name} />
              </div>
            </div>
          </div>
          <div className={styles.header}>
            <h4 className={styles.name}>{orgOrSubproject.name}</h4>
            {!!orgOrSubproject.lifetimeContributionsUsdc && orgOrSubproject.lifetimeContributionsUsdc !== 0n && (
              <div className={styles.total}>
                <h4 className={styles['total-amount']}>
                  {formatCurrency(formatUsdc(orgOrSubproject.lifetimeContributionsUsdc), {
                    compact: true,
                    lowercase: true,
                  })}
                </h4>
                <span className={styles['total-label']}>Total Donated</span>
              </div>
            )}
          </div>
          {!!orgOrSubproject.description && (
            <p className={styles.description}>{parseHtml(orgOrSubproject.description)}</p>
          )}
          {!!showPills && !isSubprojectListing(orgOrSubproject) && (
            <div className={styles.pills}>
              {orgOrSubproject.grantsReceived > 0 && (
                <Pill className={clsx(styles.pill, styles['pill--grants'])} outline>
                  {orgOrSubproject.grantsReceived} Grant{orgOrSubproject.grantsReceived > 1 && 's'}
                </Pill>
              )}
              {orgOrSubproject.donationsReceived > 0 && (
                <Pill className={clsx(styles.pill, styles['pill--donations'])} outline>
                  {orgOrSubproject.donationsReceived} Donation{orgOrSubproject.donationsReceived > 1 && 's'}
                </Pill>
              )}
            </div>
          )}
          {!isSubprojectListing(orgOrSubproject) && (
            <div className={styles.footer}>
              {!!orgOrSubproject.ein && (
                <span>
                  EIN: <em>{formatEin(orgOrSubproject.ein)}</em>
                </span>
              )}
              {!!orgOrSubproject.address && (
                <span>
                  <em>
                    {orgOrSubproject.address.city},{' '}
                    {orgOrSubproject.address.country === 'USA'
                      ? `${orgOrSubproject.address.state}, USA`
                      : `${COUNTRY_GEODATA[orgOrSubproject.address.country].name}`}
                  </em>
                </span>
              )}
            </div>
          )}
        </div>
      </Card>
    </OrgCardLinkWrapper>
  );
};
